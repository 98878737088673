// Modules
import React from 'react'
import {
  graphql,
  Link,
  StaticQuery,
} from 'gatsby'

const SitemapTags = () => (
  <>
    <h3>Tags</h3>
    <StaticQuery
      query={
        graphql`
          query BlogTagQuery {
            allMarkdownRemark(limit: 1000) {
              group(field: frontmatter___tags) {
                fieldValue
              }
            }
          }
        `
      }
      render={data => {
        const { allMarkdownRemark: { group: tags }} = data
        return (
          <div className='link-list'>
            <Link to='/blog/tags/' >All Tags</Link>

            {
              tags.map((tag, index) => {
                const { fieldValue } = tag
                const capitilizedTag = fieldValue.charAt(0).toUpperCase() + fieldValue.slice(1)

                return (
                  <Link
                    key={`tag-${index}`}
                    to={`/blog/tags/${fieldValue}/`}
                  >
                    {capitilizedTag}
                  </Link>
                )
              })
            }
          </div>
        )
      }}
    />
  </>
)

export default SitemapTags
