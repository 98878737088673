// Modules
import React from 'react'
import {
  graphql,
  Link,
  StaticQuery,
} from 'gatsby'

const SitemapBlog = () => (
  <>
    <h2>Blog</h2>
    <StaticQuery
      query={
        graphql`
          query BlogPostQuery {
            allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "blog-post"}}}, sort: {order: ASC, fields: frontmatter___date}) {
              edges {
                node {
                  fields {
                    slug
                  }
                  frontmatter {
                    title
                  }
                }
              }
            }
          }
        `
      }
      render={data => {
        const { allMarkdownRemark: { edges: posts }} = data
        return (
          <div className='link-list'>
            <Link to='/blog'>Blog Home</Link>
            {
              posts.map((post, index) => {
                const {
                  node: {
                    fields: { slug },
                    frontmatter: { title },
                  },
                } = post

                return (
                  <Link
                    key={`blog-post-${index}`}
                    to={slug}
                  >
                    {title}
                  </Link>
                )
              })
            }
          </div>
        )
      }}
    />
  </>
)

export default SitemapBlog
