// Modules
import React from 'react'
import styled from '@emotion/styled'

// Components
import Container from 'components/global-components/Container'
import Layout from 'components/global-components/Layout'
import SitemapBlog from 'components/SitemapBlog'
import SitemapPages from 'components/SitemapPages'
import SitemapTags from 'components/SitemapTags'

// Theme
import theme from 'scripts/theme'

// Styles
const SitemapStyles = styled.div`
  h1, h2, h3 { text-align: center; }
  .link-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: ${theme.space.betweenRows} ${theme.space.betweenColumns};
    text-align: center;

    a {
      color: ${theme.color.primary};
      text-decoration: underline;
    }
  }
`

const sitemap = () => (
  <Layout hasNoHero>
    <SitemapStyles>
      <Container>
        <section className='bottom-section-space'>
          <SitemapPages />
          <SitemapBlog />
          <SitemapTags />
        </section>
      </Container>
    </SitemapStyles>
  </Layout>
)

export default sitemap
