// Modules
import React from 'react'
import styled from '@emotion/styled'
import {
  graphql,
  Link,
  StaticQuery,
} from 'gatsby'

const SitemapPages = () => (
  <>
    <h1>Sitemap</h1>
    <StaticQuery
      query={
        graphql`
          query SitePageQuery {
            allMarkdownRemark(filter: {frontmatter: {pageType: {eq: "site-page"}}}) {
              edges {
                node {
                  fields {
                    slug
                  }
                }
              }
            }
          }
        `
      }
      render={data => {
        const { allMarkdownRemark: { edges: pages }} = data

        return (
          <div className='link-list'>
            <Link to='/' >Home</Link>

            {
              pages.map((page, index) => {
                const { node: { fields: { slug }}} = page
                if (slug === '/')
                  return

                const [, pageName] = slug.split('/')
                const capitilizedPageName = pageName.charAt(0).toUpperCase() + pageName.slice(1)
                return (
                  <Link
                    key={`site-page-${index}`}
                    to={slug}
                  >
                    {capitilizedPageName}
                  </Link>
                )

              })
            }
          </div>
        )
      }}
    />
  </>
)

export default SitemapPages
